import style from './FooterStyle.module.scss';

export default function Footer() {
    return(
        <footer>
            <div className={`${style.wrapper} ${style.top_wrapper} ${'container'}`}>
                <div className={style.left_item}>
                    <img src={require('./img/logofordarkbg.png')} alt="" />
                    <a href="tel:+2135509559" className={'primary_btn'}>CALL 213-550-9559</a>
                </div>
                <div className={style.right_item}>
                    <div className={style.item}>
                        <h4>NOMEX CALIFORNIA</h4>
                        <div>
                            <h6>Address</h6>
                            <p>133 N Altadena Dr, Pasadena, CA 91107</p>
                        </div>
                        <div>
                            <h6>Phone</h6>
                            <p>(213)-550-9559</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <h4>NOMEX NORTH CAROLINA</h4>
                        <div>
                            <h6>Address</h6>
                            <p>2799 Hope Church Rd, Winston-Salem, NC 27127</p>
                        </div>
                        <div>
                            <h6>Phone</h6>
                            <p>(213)-550-9559</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <h4>NOMEX NEW JERSEY</h4>
                        <div>
                            <h6>Address</h6>
                            <p>925 Paterson Plank Rd, Secaucus, NJ 07094</p>
                        </div>
                        <div>
                            <h6>Phone</h6>
                            <p>(213)-550-9559</p>
                        </div>
                    </div>
                    <div className={style.item}>
                        <h4>NOMEX FLORIDA</h4>
                        <div>
                            <h6>Address</h6>
                            <p>2281 NW 82nd Ave, Doral, FL 33122</p>
                        </div>
                        <div>
                            <h6>Phone</h6>
                            <p>(213)-550-9559</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className={`${style.wrapper} ${style.bottom_wrapper} ${'container'}`}>
                <h6>© 2023 nomex</h6>
                {/* <div>
                    <a href="#"><img src={require('./img/faceebook.png')} alt="" /></a>
                    <a href="#"><img src={require('./img/yelp.png')} alt="" /></a>
                    <a href="#"><img src={require('./img/instagram.png')} alt="" /></a>
                </div> */}
            </div>
        </footer>
    )
}