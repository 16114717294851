import { useState } from 'react';
import style from './HeaderStyle.module.scss';

export default function Header() {
    const [menuStatus, setMenuStatus] = useState(false);
    const [locationsStatus, setLocationsStatus] = useState(false);
    const [servicesStatus, setServicesStatus] = useState(false);

    return(
        <header>
            <div className={`${style.container} ${'container'}`}>
                <a href="/">
                    <img src={require('./img/dark-logo.png')} alt="nomex logo" />                    
                </a>
                <nav className={menuStatus ? style.active : ''}>
                    <div className={`${style.item} ${style.close_menu}`}>
                        <svg onClick={() => setMenuStatus(false)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none"><path d="M10.669 10.496a690745 690745 0 0 1-8.437 8.465c-.121.122-.237.253-.375.352-.342.246-.802.207-1.057-.092-.272-.317-.28-.673-.038-1.025.095-.14.23-.254.352-.376L18.024.852c.11-.11.21-.23.334-.32.365-.267.732-.268 1.062.024.309.273.322.75.044 1.102-.095.121-.212.225-.321.334l-8.474 8.502v.002Z" fill="#213251"/><path d="M9.285 10.637.85 2.172C.727 2.05.596 1.935.498 1.796.253 1.452.292.992.59.736.906.461 1.26.454 1.61.695c.14.097.253.232.375.354l16.91 16.968c.11.11.23.21.32.335.266.365.267.734-.024 1.065-.272.31-.747.323-1.099.044-.12-.096-.224-.213-.332-.322l-8.473-8.502h-.003Z" fill="#213251"/></svg>
                    </div>
                    <div className={style.item}>
                        <a href="/">Home</a>
                    </div>
                    <div className={style.item}>
                        <span onClick={() => setLocationsStatus(!locationsStatus)}>Locations <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" fill="none"><path d="M5.8 8.636l4.398-4.084c.073-.068.114-.159.114-.253s-.041-.185-.114-.253l-.005-.004c-.035-.033-.078-.06-.126-.078s-.099-.027-.15-.027-.103.009-.15.027-.09.044-.126.078L5.499 7.888l-4.14-3.846c-.036-.033-.078-.06-.126-.078s-.099-.027-.15-.027-.103.009-.15.027-.09.044-.126.078l-.005.004c-.073.068-.114.159-.114.253s.041.185.114.253L5.2 8.636a.42.42 0 0 0 .137.084.46.46 0 0 0 .326 0 .42.42 0 0 0 .137-.084z" fill="#213251"/></svg></span>
                        <div className={`${style.nav_dropdown} ${style.dropdown_content} ${locationsStatus ? style.active : ''}`}>
                            <div className={style.dropdown_wrapper}>
                                <picture>
                                    <img src={require('./img/locations.jpg')} alt="locations" />
                                </picture>
                                <div>
                                    <p>At NomEx, we pride ourselves on being where you need us. With strategically placed locations across the United States, we ensure that your furniture move is smooth and hassle-free no matter where you are. Here's a glimpse of our key service areas:</p>
                                    <span>Pasadena, CA</span>
                                    <span>Secaucus, NJ</span>
                                    <span>Winston-Salem, NC</span>
                                    <span>Doral, FL</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.item}>
                        <span onClick={() => setServicesStatus(!servicesStatus)}>Services <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" fill="none"><path d="M5.8 8.636l4.398-4.084c.073-.068.114-.159.114-.253s-.041-.185-.114-.253l-.005-.004c-.035-.033-.078-.06-.126-.078s-.099-.027-.15-.027-.103.009-.15.027-.09.044-.126.078L5.499 7.888l-4.14-3.846c-.036-.033-.078-.06-.126-.078s-.099-.027-.15-.027-.103.009-.15.027-.09.044-.126.078l-.005.004c-.073.068-.114.159-.114.253s.041.185.114.253L5.2 8.636a.42.42 0 0 0 .137.084.46.46 0 0 0 .326 0 .42.42 0 0 0 .137-.084z" fill="#213251"/></svg></span>
                        <div className={`${style.nav_dropdown} ${servicesStatus ? style.active : ''}`}>
                            <a href="/furniture">Furniture</a>
                            <a href="/antiques">Antiques</a>
                            <a href="/fragile">Fragile items</a>
                            <a href="/others">Others</a>
                        </div>
                    </div>
                    <div className={style.item}>
                        <a href="/contactus">Contact Us</a>
                    </div>
                    <div className={style.item}>
                        <a href="/aboutus">About Us</a>
                    </div>
                </nav>
                <div className={`${style.burger_menu} ${style.tablet}`} onClick={() => setMenuStatus(!menuStatus)}>
                    <svg width="29" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)" fill="#213251"><path d="M14.465 1.822H1.319c-.188 0-.38.013-.564-.02-.458-.078-.786-.48-.753-.925C.036.402.307.112.768.023c.183-.036.376-.018.566-.018h26.349c.17 0 .343-.016.509.01.49.08.777.376.808.877.028.467-.33.86-.822.92-.168.02-.34.009-.509.009H14.467l-.002.001ZM14.444 10.908H1.356c-.189 0-.382.02-.565-.014-.488-.09-.783-.392-.783-.895 0-.502.294-.802.783-.894.165-.03.338-.013.509-.013h26.404c.17 0 .345-.017.51.013.488.09.782.393.784.894 0 .502-.296.804-.783.894-.183.035-.377.015-.565.015H14.444ZM14.558 18.179h13.088c.19 0 .382-.02.566.015.49.089.784.392.784.894s-.298.801-.785.894c-.164.03-.338.013-.509.013H1.298c-.17 0-.344.017-.51-.014-.486-.093-.782-.393-.781-.896 0-.502.295-.805.784-.892.183-.034.376-.015.566-.015h13.202-.001Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h29v20H0z"/></clipPath></defs></svg>
                </div>
            </div>
        </header>
    )
}