import React from 'react';

import './style/global.scss';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './component/header/Header';
import Footer from './component/footer/Footer';

const Home = React.lazy(() => import('./component/main/Home'));
const ContactUs = React.lazy(() => import('./component/contactus/ContactUs'));
const AboutUs = React.lazy(() => import('./component/aboutus/AboutUs'));
const Furniture = React.lazy(() => import('./component/furniture/Furniture'));
const Antiques = React.lazy(() => import('./component/antiques/Antiques'));
const Quote = React.lazy(() => import('./component/quote/Quote'));
const Fragile = React.lazy(() => import('./component/fragile/Fragile'));
const Others = React.lazy(() => import('./component/others/Others'));
const QuoteThank = React.lazy(() => import('./component/quote/components/thankpage/Thank'));
const ContactUsThank = React.lazy(() => import('./component/contactus/component/thankpage/Thank'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/furniture",
    element: <Furniture />,
  },
  {
    path: "/antiques",
    element: <Antiques />,
  },
  {
    path: "/addquote",
    element: <Quote />,
  },
  {
    path: "/fragile",
    element: <Fragile />,
  },
  {
    path: "/others",
    element: <Others />,
  },
  {
    path: "/quotethank",
    element: <QuoteThank />,
  },
  {
    path: "/contactusthank",
    element: <ContactUsThank />,
  },
]);

function App() {
  return (
    <div>
      <Header />
      <RouterProvider router={router} />
      <Footer />
      <ToastContainer autoClose={7000} />
    </div>
  );
}

export default App;
